import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './useStyles'

export default () => {
  const classes = useStyles()

  return (
    <div className={classes.tableOverlay}>
      <CircularProgress color='primary'/>
    </div>
  )
}
