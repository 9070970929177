import React from 'react'
import {useStyles} from './Styles'
import { Grid,Typography } from '@mui/material'
import Box from '@mui/material/Box'
import {Images} from 'core/Themes'
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux'
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button'
import ReplayIcon from '@mui/icons-material/Replay';
import MyprofileActions from '../../features/myprofile/redux'
import LoadingButton from '@mui/lab/LoadingButton';


function OnChangingMerchantComp(props) {
    const {errors,destinationMerchant,myprofileChangeMerchant,loading} = props
    const classes = useStyles(props)
    console.log("errrss=====>",errors.length )  
    const handleRetry = ()=>{
        myprofileChangeMerchant({merchant:destinationMerchant})
    }
    return (
        <Grid className={classes.root}>
            <Box className={classes.boxRedirection}>
               {errors.length > 0
                ? <>
                    <img src={Images.merchantIcon} alt='merhcant' className={classes.merchantIcon}/>
                    <CancelIcon fontSize='large' color='error'/>
                    <br/>
                    <Typography variant='h5' textAlign='center' component='span' fontWeight='bold' color='error'>Error on changing merchant</Typography>
                    <Typography variant='h6' textAlign='center' component='span' color='error'>{errors[0]['message']}</Typography>
                    <br/>
                    <LoadingButton variant='contained' color='error' endIcon={<ReplayIcon/>} loading={loading} onClick={handleRetry}>Try Again</LoadingButton>
                  </>
                : <>
                    <img src={Images.merchantIcon} alt='merhcant' className={classes.merchantIcon}/>
                    <Typography variant='h5' textAlign='center' component='span' fontWeight='bold' color='primary'>Changing Merchant ...</Typography>
                    <br/>
                    <CircularProgress />
                 </>
               }
            </Box>
        </Grid>
    )
}

  const mapStateToProps = (state, ownProps) => {
    return {
        errors:state.myprofile.errorChangeMerchant || [],
        destinationMerchant:state.myprofile.destination_merchant_id,
        loading:state.myprofile.loadingChangeMerchant
    }
  }
  const mapDispatchToProps = dispatch => ({
    myprofileChangeMerchant: data => dispatch(MyprofileActions.myprofileChangeMerchant(data))
  })
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(OnChangingMerchantComp)
  