import _ from 'lodash'
import AppConfig from 'core/Config/AppConfig'

let appLogo = require('../Images/PlinkLiteLogos/logo.png')
if(window.location.hostname === 'dashboard-dev.plink.co.id') appLogo = require('../Images/PlinkLiteLogos/logo_development.png')
if(window.location.hostname === 'proxy.plink.co.id') appLogo = require('../Images/PlinkLiteLogos/logo_development.png')
else if(window.location.hostname === 'dashboard-staging.plink.co.id') appLogo = require('../Images/PlinkLiteLogos/logo_sandbox.png') 
else if(window.location.hostname === 'dashboard.plink.co.id') appLogo = require('../Images/PlinkLiteLogos/logo.png') 
if(window.location.hostname === 'localhost') appLogo = require('../Images/PlinkLiteLogos/logo_development.png')

const images = {
  squareImage: require('../Images/square-image.png'),
  payPagePaymentLinkLogo: require('../Images/Logo-Prismalink-242x71.png'),
  octocat: require('../Images/octocat.png'),
  visaicon: require('../Images/visa-icon.png'),
  jcbicon: require('../Images/jcb-icon.png'),
  mastercardicon: require('../Images/mastercard-icon.png'),
  pcidss: require('../Images/pcidss.png'),
  sslsecurity: require('../Images/sslsecurity.png'),
  plinkpayment: require('../Images/plinkpayment.png'),
  useravatar: require('../Images/user.png'),
  DebitinLogo: require('../Images/debitin.png'),
  LogoRp: require('../Images/Logo-Primalink-Putih.png'),
  logoPlinklite: appLogo,
  placeholderDoc: require('../Images/Placeholders/placeholder_document.png'),
  merchantIcon: require('../Images/Icons/merchant.png')
}
export default images
