import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import loadable from '@loadable/component'
import Button from '@mui/material/Button'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import _ from 'lodash'
import { isLoggedIn, checkIfPublicUrl } from '../../../Utils/Utils'
// import HeaderCon from '../containers/HeaderCon'
import AppConfig from 'core/Config/AppConfig'
import ModalCommon from '../../../Containers/Modal/index'

const HeaderCon = loadable(() => import('../containers/HeaderCon'))
const SidebarCon = loadable(() => import('../containers/SidebarCon'))
const drawerWidth = 240

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen
//   }),
//   overflowX: 'hidden'
// })

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen
//   }),
//   overflowX: 'hidden',
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up('sm')]: {
//     width: `calc(${theme.spacing(9)} + 1px)`
//   }
// })

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: 'nowrap',
//     boxSizing: 'border-box',
//     ...(open && {
//       ...openedMixin(theme),
//       '& .MuiDrawer-paper': openedMixin(theme)
//     }),
//     ...(!open && {
//       ...closedMixin(theme),
//       '& .MuiDrawer-paper': closedMixin(theme)
//     })
//   })
// )
const useStyles = (theme) => makeStyles({
  trialSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    width: '100%',
    background: 'linear-gradient(to right, #ffefba, #ffffff)',
    padding: '1rem',
    marginBottom: '2rem'
  }
})

function LayoutComp ({
  toggleDrawerFlag,
  isLogIn,
  // setToggleDrawer,
  children,
  doLogout,
  isRequesting,
  sidemenu,
  profile,
  plan,
  routeActive,
  userPrivileges
}) {
  const theme = useTheme()

  const [toggleDrawer, setToggleDrawer] = React.useState(true)

  const handleDrawerToggle = () => {
    setToggleDrawer(!toggleDrawer)
  }

  if (window.TapTalkLive !== undefined) {
    window.TapTalkLive.init(AppConfig.taptalkSecretKey)
  }
  const handleGetstartedBtn = () => {
    if (window.location.hostname === 'dashboard-staging.plink.co.id') {
      window.location.href = 'https://dashboard.plink.co.id/get-started'
    } else {
      window.location.href = '/get-started'
    }
  }
  const classes = useStyles(theme)()
  const isGetstartedIndicator =
  (userPrivileges.includes('getstrtd') && window.location.pathname !== '/get-started' && (profile.user_merchants || []).length > 0) &&
  ((plan || '').toLowerCase() === 'trial' || (_.isNil(plan) || _.isEmpty(plan)))

  return (
    // <Box sx={isMobile ? { flexGrow: 1 } : { display: 'flex' }}>
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      {(isLoggedIn(isLogIn) === true && !checkIfPublicUrl()) && <HeaderCon toggleDrawer={toggleDrawer} handleDrawerToggle={handleDrawerToggle} />}

      {(isLoggedIn(isLogIn) === true && !checkIfPublicUrl()) && <SidebarCon key={1} toggleDrawer={toggleDrawer} handleDrawerToggle={handleDrawerToggle} sidemenu={sidemenu} />}
      <Box component='main' sx={{ p: 2, marginLeft: !isMobile ? toggleDrawer ? drawerWidth + 'px' : `calc(${theme.spacing(7)} + 10px)` : 0 }}>
        {!isMobile && <DrawerHeader />}
        {(isGetstartedIndicator &&
          <Grid className={classes.trialSection}>
            <p style={{ margin: 0 }}>Anda masih dalam mode trial. Mohon lengkapi dokumen anda</p>
            <Button size='small' variant='contained' style={{ marginLeft: '1rem' }} onClick={handleGetstartedBtn}>Lengkapi Dokumen</Button>
          </Grid>
        )}
        {children}
        <ModalCommon />
        <br/>
        <br/>
        <br/>
      </Box>
    </Box>
  )
}

export default LayoutComp
