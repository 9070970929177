import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'
import Actions from './redux'
import {callToastr} from '../../core/Utils/Utils'
import LoginActions from '../../core/Containers/Login/redux'
import TablePaginationActions from '../../core/features/TablePagination/redux'
import _ from 'lodash'

export function * triggerHitCallback (api, { data }) {
  console.log('triggerHitCallback===>', data)
  const response = yield call(api.triggerHitMerchantCallback, data)
  const errors = path(['data', 'errors'], response) || []
  if (!_.isEmpty(response.problem)) errors.push({ message: response.problem })
  console.log('response===>', response)
  const errorBody = path(['data', 'data', 'hitMerchantCallbackByTrx', 'error'], response)
  const callbackData = path(['data', 'data', 'hitMerchantCallbackByTrx', 'callbackData'], response) || []
  if (!_.isEmpty(errorBody)) errors.push({ message: errorBody })
  
  if (!_.isEmpty(errors) && (_.isEqual((errors[0] || {}).message, 'Invalid Access Token') || _.isEqual((errors[0] || {}).message, 'jwt expired'))) {
    yield put(LoginActions.loginDoLogout({}))
  } else {
    if(_.isEmpty(errors)){
      callToastr('Succeed hit callback','success')
    }else{
      callToastr('Ada Kesalahan. '+typeof(errors[0]) === 'string'? errors[0] : errors[0]['message'],'error')
    }
  }
  yield put(Actions.triggerMerchantHitCallbackDone({}))
  if(!_.isNil(callbackData)){
      yield put(TablePaginationActions.setReloadDetail({serviceName:data.tablePaginationServiceName}))   
  }
}