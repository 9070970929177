import { makeStyles } from "@mui/styles"
import {grey,blue,red} from "@mui/material/colors"
import { height, width } from "@mui/system"

export const useStyles = makeStyles((theme)=>({
    root: {
        height: "100vh",
        background:blue[50],
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width:'100%',
        display:'flex',
        alignItems: "center",
        justifyContent: "center"
    },
    boxRedirection:{
        display:'flex',
        boxSizing:'border-box',
        background:'#fff',
        minWidth:'40%',
        minHeight:'50%',
        padding:'2rem',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        [theme.breakpoints.down('xs')]: {
            minWidth:'40%',
            minHeight:'50%',
        },
        [theme.breakpoints.down('md')]: {
            minWidth:'40%',
            minHeight:'50%',
        },
        [theme.breakpoints.up('md')]: {
            minWidth:'50%',
            minHeight:'50%',
        },
        [theme.breakpoints.up('lg')]: {
            minWidth:'30%',
            minHeight:'50%',
        },
        borderBottom:props=>`5rem solid ${(props.errors).length > 0 ? red[500] : blue[400]}`
    },
    merchantIcon:{
        width:'5rem',
        marginBottom:'3rem'
    }
}))