import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  triggerMerchantHitCallback: ['data'],
  triggerMerchantHitCallbackDone: ['data'],
  reset: null
})

export const MerchantCallbackTriggerTypes = Types
export default Creators
export const INITIAL_STATE = Immutable({
    exporting:{},
    data:{},
    ishitting:false    
})

export const triggerMerchantHitCallback = (state, { data }) => state.merge({ ...state, ...data,ishitting:true })
export const triggerMerchantHitCallbackDone = (state, { data }) => state.merge({ ...state, ...data,ishitting:false })


export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRIGGER_MERCHANT_HIT_CALLBACK]: triggerMerchantHitCallback,
  [Types.TRIGGER_MERCHANT_HIT_CALLBACK_DONE]: triggerMerchantHitCallbackDone,
  [Types.RESET]: (state) => INITIAL_STATE
})
