import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'
import Actions from './redux'
import TablepaginationActions from 'core/features/TablePagination/redux'
import ManifestToolsOctocashRevoke from 'Pages/ToolsOctocashRevoke/Manifest'
import LoginActions from '../../core/Containers/Login/redux'

import _ from 'lodash'
import { callToastr, getErrorMessage } from 'core/Utils/Utils'

export function * fetchToolsOctocashRevoke (api, { data }) {
  const serviceName = data.merchantId === undefined ? 'fetchMerchantSecretKey' : 'fetchMerchantSecretKeyById'
  const responseName = data.merchantId === undefined ? 'generateMerchantKey' : 'generateMerchantKeyById'

  const response = yield call(api[serviceName], data)

  const errors = path(['data', 'errors'], response) || []
  if (!_.isEmpty(response.problem)) errors.push({ message: response.problem })
  console.log('response===>', response)
  const errorBody = path(['data', 'data', responseName, 'error'], response)
  const scrt = path(['data', 'data', responseName, 'secret_key'], response)
  const keyId = path(['data', 'data', responseName, 'key_id'], response)

  if (!_.isEmpty(errorBody)) errors.push({ message: errorBody })
  if (!_.isEmpty(errors) && (_.isEqual((errors[0] || {}).message, 'Invalid Access Token') || _.isEqual((errors[0] || {}).message, 'jwt expired'))) {
    yield put(LoginActions.loginDoLogout({}))
  } else if (!_.isEmpty(errors)) {
    yield put(Actions.fetchMerchantSecretKeyDone({ openModal: false }))
    console.log('data error=======', errors)
    callToastr(`Ada Kesalahan. ${typeof (errors[0]) === 'string' ? errors[0] : errors[0].message}`, 'error')
  } else {
    callToastr('Success Generating Secret Key', 'success')
    yield put(TablepaginationActions.setReloadDetail({ serviceName: data.serviceReference }))
    yield put(Actions.fetchMerchantSecretKeyDone({ openModal: true, scrt, keyId }))
  }
}
export function * submitDebitinCimbRevoke(api, { data }) {
  const responseName = 'submitDebitinCimbRevoke';
  const response = yield call(api.submitDebitinCimbRevoke, data)
  console.log('response===>', response)
  const errors = path(['data', 'errors'], response) || []
  if (!_.isEmpty(response.problem)) errors.push({ message: response.problem })
  const errorBody = path(['data', 'data', responseName, 'error'], response)
  if (!_.isEmpty(errorBody)) errors.push({ message: errorBody })
  if (!_.isEmpty(errors) && (_.isEqual((errors[0] || {}).message, 'Invalid Access Token') || _.isEqual((errors[0] || {}).message, 'jwt expired'))) {
    yield put(LoginActions.loginDoLogout({}))
  } else if (!_.isEmpty(errors)) {
    yield put(Actions.submitOctocashRevokeDone({ openModal: false }))
    console.log('data error=======', errors)
    callToastr(`Ada Kesalahan. ${getErrorMessage(typeof (errors[0]) === 'string' ? errors[0] : errors[0].message)}`, 'error')
    yield put(TablepaginationActions.tablepaginationFetchDataReload({ needReload: { [ManifestToolsOctocashRevoke.listallService]: true } }))
  } else {
    callToastr('Success revoke', 'success')
    // yield put(TablepaginationActions.setReloadDetail({ serviceName: data.serviceReference }))
    // need reload
    yield put(Actions.submitOctocashRevokeDone({ openModal: true }))
    yield put(TablepaginationActions.tablepaginationFetchDataReload({ needReload: { [ManifestToolsOctocashRevoke.listallService]: true } }))
  }
}