import { generateHmac, getAccessToken } from '../../core/Utils/Utils'
import AppConfig from 'core/Config/AppConfig'
export const create = api => ({
  sendEmailPaymentlink: ({ emails,content }) => {
    let listEmail = ''
    listEmail += '['
    emails.forEach(element => {
      listEmail += `"${element}",` 
    });
    listEmail += ']'

    const body = { query: ` mutation{ sendPaymentlinkToEmails(request:{emails:${listEmail},emailContent:"${content}"}){ status error } }` }
    console.log('body==>', JSON.stringify(body))
    api.setHeader('hmac', generateHmac(JSON.stringify(body)))
    api.setHeader('AccessToken', getAccessToken())
    return api.post(AppConfig.graphqlPath, body)
  }
})
