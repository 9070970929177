import { getAccessToken } from '../../core/Utils/Utils'
import AppConfig from 'core/Config/AppConfig'


export const create = api => ({
  fetchDataExport:  ({ payload,endPoint,fileType }) => {
    api.setHeader('AccessToken', getAccessToken())
    return  api.post(`${process.env.REACT_APP_DEV_HOST_PROXY || AppConfig.hostBackend }${endPoint}`, JSON.stringify(payload))
    // return api.post(`${process.env.REACT_APP_DEV_HOST_PROXY || AppConfig.hostBackend }${endPoint}`, JSON.stringify(payload),{responseType:'blob'})
  
  }
})
