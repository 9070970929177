import { createReducer, createActions } from 'reduxsauce'
import { isBrowser } from 'react-device-detect'
import Immutable from 'seamless-immutable'
// import { arrayMerge } from '../../Utils/helper/datamining'
// import { isEmpty } from 'ramda'
// import AppConfig from '../../Config/AppConfig'
// import {setSession,getSession, makeData} from '../../Utils/Utils'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginPatch: ['data'],
  loginDoLogin: ['data'],
  loginDoLogout: ['data'],
  loginCheckLogin: ['data'],
  loginReset: ['data'],
  loginDoLoginFailed: ['data'],
  loginDoLoginSuccess: ['data'],
  loginDoLogoutSuccess: ['data'],
  setAuthorizedRouters: ['data'],
  setToggleDrawer: ['data'],
  reset: null
})

export const LoginTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  // token:'',
  merchant_id: '',
  data: {},
  fetching: null,
  payload: null,
  single: null,
  multi: null,
  error: null,
  allIds: [],
  byId: {},
  maxModifiedon: 0,
  isLoggedIn: null,
  token: null,
  expiresIn: 0,
  refreshToken: '',
  scope: '',
  tokenType: '',
  formSubmitMessage: '',
  isRequesting: false,
  isLoggingOut: false,
  responseMessage: '',
  responseCode: '',
  version: 0,
  userFullName: '',
  responseDescription: '',
  userRole: '',
  userMerchantCode: '',
  userMerchantId: '',
  sessionToken: null,
  authorizedRouters: [],
  user: {},
  toggleDrawer: isBrowser
})

/* ------------- Selectors ------------- */

export const LoginSelectors = {
  getData: state => state.data,
  getSingle: state => state.single,
  getDetailById: (state, id) => (state.byId || {})[id],
  getAllIds: state => state.allIds,
  getAllDataArr: state => state.allIds.map(id => (state.byId || {})[id]),
  getMaxModifiedon: state => state.maxModifiedon,
  getById: state => state.byId,
  isLoggedIn: state => state.isLoggedIn,
  getFormSubmitMessage: state => state.formSubmitMessage,
  getError: state => state.error,
  getToken: state => state.token,
  isRequesting: st => st.isRequesting,
  sessionToken: st => st.sessionToken,
  responseMessage: st => st.responseMessage,
  responseCode: st => st.responseCode,
  userFullName: st => st.userFullName,
  responseDescription: st => st.responseDescription,
  userRole: st => st.userRole,
  getUserRole: st => st.userRole,
  userMerchantCode: st => st.userMerchantCode,
  getUserMerchantCode: st => st.userMerchantCode,
  userMerchantId: st => st.userMerchantId,
  getAuthorizedRouters: st => st.authorizedRouters,
  getIsLoggingOut:st=>st.isLoggingOut
}

/* ------------- Reducers ------------- */

// Something went wrong somewhere.
export const failure = (state, action) => {
  const { data } = action
  console.log('Response message>>>>', data.formSubmitMessage)
  return state.merge({
    fetching: false,
    error: true,
    payload: null,
    formSubmitMessage: data.formSubmitMessage
  })
}
export const reset = (state, action) => state.merge(INITIAL_STATE)

export const loginDoLogin = (state, { data }) => {
  console.log('loginDoLogin====')
  data.isRequesting = true
  return loginPatch(state, { data })
}
export const loginDoLogout = (state, { data }) => {
  data.isLoggingOut = true
  data.isRequesting = true
  return loginPatch(state, { data })
}
export const loginDoLoginSuccess = (state, { data }) => {
  const { responseCode, responseMessage, token } = data
  data.token = token
  data.userMerchantCode = token
  data.isRequesting = false
  data.isLoggedIn = true
  data.responseCode = responseCode
  data.responseMessage = responseMessage

  return loginPatch(state, { data })
}
export const loginDoLoginFailed = (state, { data }) => {
  data.isRequesting = false

  return loginPatch(state, { data })
}

export const loginDoLogoutSuccess = (state, { data }) => {
  data.isRequesting = false
  data.isLoggedIn = false
  data.isLoggingOut = false
  data.sessionToken = null
  data.userMerchantCode = ''
  data.userMerchantId = ''
  data.myprofile = {}
  return state.merge(data)
  // return loginPatch(state, { data })
}

export const loginCheckLogin = (state, { data }) => {
  data.isRequesting = true
  return state.merge(data)
}

export const loginPatch = (state, { data }) => {
  const mergeData = {}
  if ('user' in data) mergeData.user = data.user
  if ('merchant_id' in data) mergeData.merchant_id = data.merchant_id
  if ('isRequesting' in data) mergeData.isRequesting = data.isRequesting
  if ('isLoggingOut' in data) mergeData.isLoggingOut = data.isLoggingOut
  if ('responseCode' in data) mergeData.responseCode = data.responseCode
  if ('responseMessage' in data) mergeData.responseMessage = data.responseMessage
  if ('isLoggedIn' in data) mergeData.isLoggedIn = data.isLoggedIn
  // if (data.isLoggedIn) mergeData.isLoggedIn = data.isLoggedIn
  if ('responseDescription' in data) mergeData.responseDescription = data.responseDescription
  if ('userFullName' in data) mergeData.userFullName = data.userFullName
  if ('userRole' in data) mergeData.userRole = data.userRole
  if ('sessionToken' in data) mergeData.sessionToken = data.sessionToken
  if ('userMerchantCode' in data) mergeData.userMerchantCode = data.userMerchantCode
  if ('userMerchantId' in data) mergeData.userMerchantId = data.userMerchantId
  mergeData.version = state.version + 1
  return state.merge(mergeData)
}
export const setAuthorizedRouters = (state, { data }) => state.merge({ authorizedRouters: data })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_RESET]: reset,
  [Types.LOGIN_PATCH]: loginPatch,
  [Types.LOGIN_CHECK_LOGIN]: loginCheckLogin,
  [Types.LOGIN_DO_LOGIN]: loginDoLogin,
  [Types.LOGIN_DO_LOGIN_SUCCESS]: loginDoLoginSuccess,
  [Types.LOGIN_DO_LOGIN_FAILED]: loginDoLoginFailed,
  [Types.LOGIN_DO_LOGOUT]: loginDoLogout,
  [Types.LOGIN_DO_LOGOUT_SUCCESS]: loginDoLogoutSuccess,
  [Types.SET_AUTHORIZED_ROUTERS]: setAuthorizedRouters,
  [Types.SET_TOGGLE_DRAWER]: (state, { data }) => state.merge({ toggleDrawer: data.toggleDrawer }),
  [Types.RESET]: (state) => INITIAL_STATE
})
