import React from 'react'
import { Backdrop,CircularProgress, Typography } from '@mui/material'

export default function OnLoggingoutComp() {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        <div style={{zIndex:11000,textAlign:'center'}}>
            <CircularProgress  color="inherit" />
            <Typography variant='h6' gutterBottom> Logging Out ...</Typography>
        </div>
    </Backdrop>
  )
}
