
import React from 'react'
import _ from 'lodash'
import EmailIcon from '@mui/icons-material/Email';
import { Button } from '@mui/material';

export default function MailSendButtonComp(props) {
    const { customButton,doToogleModal,params } = props
    const injectCustomButton = () =>{
        const action = handleOpen
        return customButton({action})
    }

    const handleOpen = () =>{
        doToogleModal({isOpen:true,paymentlink:params.paymentlink})
    }

    return (<>
        {_.isNil(customButton)
                ? <Button variant='outlined' onClick={handleOpen}  startIcon={<EmailIcon/>} >Send Mail</Button>
                : injectCustomButton()
        }
    </>)
}
