import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  fetchToolsOctocashRevoke:['data'],
  fetchToolsOctocashRevokeDone:['data'],
  submitOctocashRevoke:['data'],
  submitOctocashRevokeDone:['data'],
  resetToolsOctocashRevoke:['data'],
  reset: null
})

export const ToolsOctocashRevokeTypes = Types
export default Creators
export const INITIAL_STATE = Immutable({
  loading: false,
  error:'',
  openModal: false
})

export const fetchToolsOctocashRevoke = (state, { data }) => state.merge({...state,loading: true})

export const fetchToolsOctocashRevokeDone = (state, { data }) => state.merge({...state,loading: false})
export const submitOctocashRevoke = (state, { data }) => state.merge({...state,loading: true})

export const submitOctocashRevokeDone = (state, { data }) => state.merge({...state,loading: false, openModal: data.openModal})

export const resetToolsOctocashRevoke = (state, { data }) => state.merge(INITIAL_STATE)

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_TOOLS_OCTOCASH_REVOKE]: resetToolsOctocashRevoke,
  [Types.FETCH_TOOLS_OCTOCASH_REVOKE]: fetchToolsOctocashRevoke,
  [Types.FETCH_TOOLS_OCTOCASH_REVOKE_DONE]: fetchToolsOctocashRevokeDone,
  [Types.SUBMIT_OCTOCASH_REVOKE]: submitOctocashRevoke,
  [Types.SUBMIT_OCTOCASH_REVOKE_DONE]: submitOctocashRevokeDone,
  [Types.RESET]: (state) => INITIAL_STATE
})
