import { makeStyles } from "@mui/styles"
import { blue } from "@mui/material/colors"
import {grey} from "@mui/material/colors"
import { width } from "@mui/system"

export const useStyles = makeStyles((theme)=>({
    tableOverlay:{
        display:'flex',
        justifyContent:'center',
        zIndex:50,
        background:'rgba(255,255,255,.7)',
        alignItems:'center',
        width:'100%',
        height:'100%',
        left:0,
        top:0,
        position:'absolute'
    },
    tableWrapper:{
        position:'relative',
        display:'flex',
        flexDirection:'column'
    }
}))