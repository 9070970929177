import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

const { Types, Creators } = createActions({
  doPayVirtualAccount: ['data'],
  doSendEmail:['data'],
  doSendEmailDone:['data'],
  doToogleModal:['data'],
  reset: null
})

export const MailSendTypes = Types
export default Creators
export const INITIAL_STATE = Immutable({
  status:null,
  error:'',
  loading:false,
  openModal:false,
  paymentlink:null
})

export const doSendEmail = (state, { data }) => state.merge({
  ...state,
  loading:true,
})

export const doSendEmailDone = (state, { data }) => state.merge({
  ...state,
  loading:false
})

export const doToogleModal = (state, { data }) => {
  let st = {}
  st = { ...state, openModal:data.isOpen}
  st['paymentlink'] = _.isNil(data.paymentlink) || _.isEmpty(data.paymentlink) ? null : data.paymentlink
  return state.merge(st)
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DO_SEND_EMAIL] : doSendEmail,
  [Types.DO_SEND_EMAIL_DONE] : doSendEmailDone,
  [Types.DO_TOOGLE_MODAL] : doToogleModal,
  [Types.RESET]: (state) => INITIAL_STATE
})
