import React from 'react'
import MailSendButtonComp from '../components/MailSendButtonComp'
import SendEmailActions from '../redux'
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    return {
      loading: state.sendemail.loading,
      error:state.sendemail.error,
      openModal:state.sendemail.openModal,
      paymentlink:state.sendemail.paymentlink,
      merchantName:state.myprofile.merchant.merchant_name
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      doSendEmail: data => dispatch(SendEmailActions.doSendEmail(data)),
      doToogleModal: data => dispatch(SendEmailActions.doToogleModal(data))
    }
  }
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MailSendButtonComp)
  