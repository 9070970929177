import { getAccessToken,generateHmac } from '../../core/Utils/Utils'
import AppConfig from 'core/Config/AppConfig'


export const create = api => ({
  triggerHitMerchantCallback: ({ mercRefNo }) => {
    const body = { query: `query{ hitMerchantCallbackByTrx(mercRefno:"${mercRefNo}"){ error status callbackData { plinkRefNo merchantRefNo merchantCode url requestBody responseBody createdAt updatedAt paymentMethod actionId terminalId status errorMessage requestHeader process responseCode  } }}` }
    console.log('body==>', JSON.stringify(body))
    api.setHeader('hmac', generateHmac(JSON.stringify(body)))
    api.setHeader('AccessToken', getAccessToken())
    return api.post(AppConfig.graphqlPath, body)
  },
})
