import React from 'react'
import { connect } from 'react-redux'
import { CardWrapper, OverlaySpin, SearchFieldCon,TableSkeleton } from '../index'

const Loading = connect((state, ownProps) => ({ loading: (state.tablepagination.loading || {})[ownProps.serviceName] }))((props) => {
  const { loading,type } = props
  if (!loading) return null
  
  return(
    <OverlaySpin />
  )
})

const CardWrapperCon = (props) => <CardWrapper SearchField={SearchFieldCon} Loading={Loading} {...props} />

export default CardWrapperCon
