import React from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AppConfig from '../../core/Config/AppConfig'
import { toIdr, sanitizeValue, getPartnerName, toLocalTime } from '../../core/Utils/Utils'
// import { MenuList,MenuItem } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const basePath = AppConfig.basePath

const entityName = 'TbToolDebitinCimbRevokeHistory'
const serviceEntity = 'TbToolDebitinCimbRevokeHistory'

export const redirectAfterCreate = '/report/transaction-detail'
export const redirectAfterDelete = '/report/all-transaction'
export const detailPageUrl = (id) => (`/report/transaction-detail/${id}`)
export const upsertPageUrl = (id) => (`/report/transaction-form${id ? '/' + id : ''}`)
export const listallPageUrl = () => ('/report/all-transaction')
export const createNewButtonLabel = 'Create New Transaction'
export const createPageTitle = 'Create New Transaction'
export const listallPageTitle = 'Transaction Report'
export const detailPageTitle = 'Transaction Detail'
export const upsertPageTitle = 'Form ' + entityName
export const detailService = 'getDetail' + serviceEntity
export const upsertService = 'upsert' + serviceEntity
export const listallService = 'getAll' + serviceEntity + 's'
export const deleteService = 'delete' + serviceEntity
export const fields = 'sessionId,merchantId,merchantCode,description,status,createdDt,responseBody,createdByName'
export const fieldsDetail = `
  plTransactions_detail{
    accountNumber partnerCode mercNm externalId invoiceNumber id pymtMethodCd paySts payDt trxCreationDt trxAmt ecommRefNo payBnkRefNo mercId mercCd mercRefNo mercNm pymtMethodCd pymtMethodNm coCcyCd validity coCcyAmt
  }
  merchantcallback_detail{
    id plinkRefNo merchantRefNo merchantCode url requestBody responseBody createdAt updatedAt paymentMethod actionId terminalId status errorMessage requestHeader process responseCode
  }
`
const stat = [
  { status: 'SETLD', text: 'Dibayar', class: 'btn-success btn-sm' },
  { status: 'SETTL', text: 'Dibayar', class: 'btn-success btn-sm' },
  { status: 'PAID', text: 'Dibayar', class: 'btn-success btn-sm' },
  { status: 'PNDNG', text: 'Menunggu Pembayaran', class: 'btn-warning btn-sm' },
  { status: 'REJEC', text: 'Pembayaran Gagal', class: 'btn-danger btn-sm' },
  { status: 'REJCT', text: 'Pembayaran Gagal', class: 'btn-danger btn-sm' },
  { status: 'CANCL', text: 'Pembayaran Dibatalkan', class: 'btn-secondary btn-sm' }
]
const statusTxt = status => {
  const found = stat.find(r => r.status === status)
  return found ? found.class : 'btn-default btn-sm'
}

function BasicMenu ({ history, idValue, transactionCheckStatusPayment, paymentStatus, loadingCheckStatusPayment,responseBody }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (menu) => {
    if (menu === 'detail') {
      setAnchorEl(null)
      const win = window.open(`${basePath}${redirectAfterCreate}/${idValue}`, '_blank');
      win.focus();
    } else if (menu === 'checkstatus') {
      // check status
      if (!loadingCheckStatusPayment) transactionCheckStatusPayment({ id: idValue })
    } else {
      setAnchorEl(null)
    }
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{ width: '20px', height: '20px' }}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem key={1} onClick={() => handleClose('detail')}>Detail</MenuItem>
      </Menu>
    </div>
  )
}

export const getColumns = (history, transactionCheckStatusPayment, paymentStatus, loadingCheckStatusPayment,responseBody) => [
  // {
  //   Header: '',
  //   accessor: 'id',
  //   Cell: p => (
  //     <BasicMenu history={history} idValue={p.cell.value} transactionCheckStatusPayment={transactionCheckStatusPayment} paymentStatus={paymentStatus} loadingCheckStatusPayment={loadingCheckStatusPayment} responseBody={responseBody}/>
  //   )
  // },
  { Header: 'Merchant Code', accessor: p => sanitizeValue(p.merchantCode)},
  { Header: 'Session Id', accessor: p => sanitizeValue(p.sessionId)},
  { Header: 'Revoke By', accessor: p => sanitizeValue(p.createdByName)},
  { Header: 'Description', accessor: p => sanitizeValue(p.description)},
  { Header: 'Status', accessor: p => sanitizeValue(p.status)},
  { Header: 'Revoke Date', accessor: p => sanitizeValue(p.createdDt)},
  { Header: 'Response Body', accessor: p => sanitizeValue(p.responseBody)}
]

export const exportDataOptions = {
  endPoint: '/api/render/document/pltransactions',
  fileName: 'Data Transaction',
  exportId: listallService,
  buildPayload: (state, exportId) => {
    const index = _.has(state.tablepagination.pageIndex, exportId) ? state.tablepagination.pageIndex[exportId] : 0
    const size = _.has(state.tablepagination.pageSize, exportId) ? state.tablepagination.pageSize[exportId] : 0
    const filter = _.has(state.tablepagination.filterFormInput, exportId) ? state.tablepagination.filterFormInput[exportId] : 0
    return { page_index: index, page_size: size, filter: { ...filter } }
  }
}

export const reportOptions = {
  serviceName: 'getPlTransactionReports',
  listServiceName: listallService,
  reportFields: 'totalAmountTrx,totalAmountTrxSettled,totalAmountTrxPending,totalAmountTrxReject,totalAmountTrxCancel,totalTrx,totalTrxSettled,totalTrxPending,totalTrxReject,totalTrxCancel'
}

export default {
  reportOptions,
  redirectAfterCreate,
  redirectAfterDelete,
  detailPageUrl,
  upsertPageUrl,
  createNewButtonLabel,
  createPageTitle,
  listallPageTitle,
  detailPageTitle,
  detailService,
  listallService,
  deleteService,
  upsertService,
  fields,
  getColumns,
  listallPageUrl
}
