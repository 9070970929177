import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'
import Actions from './redux'
import {callToastr} from '../../core/Utils/Utils'
import _ from 'lodash'
import LoginActions from 'core/Containers/Login/redux'


export  function * fetchDataExport (api, { data }) {
  const response = yield call(api.fetchDataExport, data)
  console.log("response=====>",response)
  const errors = path(['data', 'errors'], response) || []
  if (!_.isEmpty(response.problem)) errors.push({ message: response.problem })

  if(response?.headers?.['content-type'] === `application/json;charset=UTF-8` || response?.headers?.['content-type'] === `application/json`){
    if(!_.isNil(response?.data.error )){
      errors.push({ message:response?.data.detailMessage} )
    }
  }

  

  if (!_.isEmpty(errors) && (_.isEqual((errors[0] || {}).message, 'Invalid Access Token') || _.isEqual((errors[0] || {}).message, 'jwt expired'))) {
    yield put(LoginActions.loginDoLogout({}))
    callToastr(`Ada kesalahan. sesi sudah berakhir`,'error')
    yield put(Actions.fetchExportDataDone({serviceName:data.serviceName,error:errors[0]}))
  } else if (!_.isEmpty(errors)) {
    callToastr(`Gagal mengekspor data. ${errors[errors.length-1]?.message}`,'error')
    yield put(Actions.fetchExportDataDone({serviceName:data.serviceName,error:errors[errors.length-1]?.message}))
  } else {
    response.data = new Blob([response.data || ''])
    const fileName = response.headers['content-disposition'].split('filename= ')[1]
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    callToastr(`Sukses mengekspor data`,'success')
    yield put(Actions.fetchExportDataDone({serviceName:data.serviceName}))
  }
}
